<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <!--  Picture modal  -->
    <b-modal
        id="picture-modal"
        ok-variant="primary"
        title="Picture"
        ok-title="Download"
        cancel-title="Dismiss"
        centered
        @ok="downloadPicture"
    >
      <b-row>
        <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
        >
          <img style="max-width:100%;width: max-content;min-height: 30rem" :src="baseUrl+picture"/>
        </b-col>
      </b-row>
    </b-modal>
    <!--  Picture modal  -->
    <div v-if="order">
      <!-- Table Container Card -->
      <b-card
          no-body
          class=""
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h2>Order items</h2>
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="order.orderItems"
            responsive
            :fields="myTableColumnsSubOrders"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found!"
        >

          <!-- Column: productInfo -->
          <template #cell(productInfo)="data">
            <span>
               {{ data.item.productInfo.title }}
            </span>
          </template>

          <!-- Column: amount -->
          <template #cell(amount)="data">
            <span>
               {{ makePrettyPrice(data.item.amount) }}
            </span>
          </template>

        </b-table>
      </b-card>

      <b-card
          no-body
          class="mt-3"
      >
        <div class="m-2" style="overflow-x: auto">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h2>Milestones</h2>
            </b-col>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!--     Stepper      -->
              <div class="stepwizard col-md-offset-3 w-100">
                <div class="stepwizard-row setup-panel">
                  <div class="stepwizard-step">
                    <span class="btn btn-primary btn-circle">1</span>
                    <p class="text-primary">Pre order</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(1)" :class="[selectedStep === 1 ? 'btn-warning' : '',order.orderStatus >= 1 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">2</span>
                    <p :class="[selectedStep === 1 ? 'text-warning' : '',order.orderStatus >= 1 ? 'text-primary' : '']">Pending</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(2)" :class="[selectedStep === 2 ? 'btn-warning' : '',order.orderStatus >= 2 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">3</span>
                    <p :class="[selectedStep === 2 ? 'text-warning' : '',order.orderStatus >= 2 ? 'text-primary' : '']">Waiting for admin</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(4)" :class="[selectedStep === 4 ? 'btn-warning' : '',order.orderStatus >= 4 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">4</span>
                    <p :class="[selectedStep === 4 ? 'text-warning' : '',order.orderStatus >= 4 ? 'text-primary' : '']">Paid</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(5)" :class="[selectedStep === 5 ? 'btn-warning' : '',order.orderStatus >= 5 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">5</span>
                    <p :class="[selectedStep === 5 ? 'text-warning' : '',order.orderStatus >= 5 ? 'text-primary' : '']">Sorting</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(6)" :class="[selectedStep === 6 ? 'btn-warning' : '',order.orderStatus >= 6 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">6</span>
                    <p :class="[selectedStep === 6 ? 'text-warning' : '',order.orderStatus >= 6 ? 'text-primary' : '']">Packeting</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(7)" :class="[selectedStep === 7 ? 'btn-warning' : '',order.orderStatus >= 7 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">7</span>
                    <p :class="[selectedStep === 7 ? 'text-warning' : '',order.orderStatus >= 7 ? 'text-primary' : '']">Preparing</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(8)" :class="[selectedStep === 8 ? 'btn-warning' : '',order.orderStatus >= 8 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">8</span>
                    <p :class="[selectedStep === 8 ? 'text-warning' : '',order.orderStatus >= 8 ? 'text-primary' : '']">Shipping from origin to destination</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(9)" :class="[selectedStep === 9 ? 'btn-warning' : '',order.orderStatus >= 9 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">9</span>
                    <p :class="[selectedStep === 9 ? 'text-warning' : '',order.orderStatus >= 9 ? 'text-primary' : '']">Shipping completed</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span @click="changeStep(10)" :class="[selectedStep === 10 ? 'btn-warning' : '',order.orderStatus >= 10 ? 'btn-primary' : '']" class="btn btn-circle" disabled="disabled">10</span>
                    <p :class="[selectedStep === 10 ? 'text-warning' : '',order.orderStatus >= 10 ? 'text-primary' : '']">Order closed</p>
                  </div>
                </div>
              </div>
              <!--     Stepper      -->
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!--  Attachments    -->
      <b-card no-body>
        <b-col
            cols="12"
            class=""
        >
          <div class="my-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <h2>Attachments</h2>
              </b-col>
            </b-row>
          </div>
          <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="order.attachments"
              responsive
              :fields="myTableColumnsSubOrders2"
              primary-key="id"
              show-empty
              bordered
              striped
              empty-text="Nothing found!"
          >
            <template #cell(orderStatus)="data">
              <span v-if="data.item.orderStatus === 1">Pending</span>
              <span v-if="data.item.orderStatus === 2">Waiting for admin</span>
              <span v-if="data.item.orderStatus === 3">Payment in advanced</span>
              <span v-if="data.item.orderStatus === 4">Payed</span>
              <span v-if="data.item.orderStatus === 5">Sorting</span>
              <span v-if="data.item.orderStatus === 6">Packeting</span>
              <span v-if="data.item.orderStatus === 7">Preparing</span>
              <span v-if="data.item.orderStatus === 8">Shipping from origin to destination</span>
              <span v-if="data.item.orderStatus === 9">Shipping completed</span>
              <span v-if="data.item.orderStatus === 10">Order closed</span>
            </template>
            <template #cell(files)="data">
              <div class="w-100 d-flex justify-content-start align-items-center">
                <img @click="picture = file.imageUrl" v-b-modal.picture-modal class="m-1" v-for="file in data.item.files" style="width: 5rem;max-height: 5rem" :src="baseUrl+file.imageUrl" alt="">
              </div>
            </template>
          </b-table>
        </b-col>
      </b-card>
      <!--  Attachments    -->

      <!--  File Uploader    -->
      <b-card v-if="selectedStep !== 10">
        <b-col
            cols="12"
            class=""
        >
          <b-row>
            <h4 class="col-12">File uploader</h4>

            <b-col cols="12" md="6">
              <template>
                <p class="my-1">picture</p>
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    class="cursor-pointer"
                    rounded
                    size="150px"
                    @click.native="$refs.refInputEl.click()"
                >
                  <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
                </b-avatar>
              </template>
              <b-button
                  class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 d-none"
                  variant="warning"
                  @click="$refs.refInputEl.click()"
              >
                <input
                    ref="refInputEl"
                    accept=".jpg,.jpeg,.png,.svg"
                    class="d-none"
                    type="file"
                    @input="setCover($event)"
                >
              </b-button>
            </b-col>

            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2 mt-2"
                  variant="primary"
                  @click="sendFile"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!--  File Uploader    -->

      <!--  Live    -->
      <b-card v-if="selectedStep === 5 || selectedStep === 6">
        <b-col
            cols="12"
            class=""
        >
          <b-row>
            <h4 class="col-12 mb-2">Live</h4>
            <b-col cols="12">
              <LiveStream :order="order" :selectedStep="selectedStep"/>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!--  Live    -->

      <!--  GPS    -->
      <b-card v-if="selectedStep === 8">
        <b-col
            cols="12"
            class=""
        >
          <b-row>
            <h4 class="col-12 mb-2">GPS tracking</h4>
            <b-col cols="12">
              <b-button
                  variant="outline-primary"
              >
                <feather-icon
                    icon="CompassIcon"
                    class="mr-50"
                />
                <span class="align-middle">Start live tracking</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!--  GPS    -->

      <b-card v-if="selectedStep === 2">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="togglePayment"
              >
                Toggle payment paid status
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 4">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="moveToSortingStep"
              >
                Move to Sorting step
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 5">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="moveToPacketingStep"
              >
                Move to Packeting step
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 6">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="moveToPreparingStep"
              >
                Move to Preparing step
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 7">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="moveToShippingStep"
              >
                Move to Shipping step
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 8">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="moveToShippingCompletedStep"
              >
                Move to Shipping completed step
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 9">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2"
                  variant="primary"
                  @click="moveToOrderCompletedStep"
              >
                Move to Order completed step
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <b-card v-if="selectedStep === 10">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-alert
                  variant="success"
                  show
              >
                <h4 class="alert-heading">
                  Congratulations
                </h4>
                <div class="alert-body">
                  <span>This order has been completed!</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BFormTextarea, BFormGroup, BAlert, BModal
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {
  OrderCloseOrderMilestone,
  OrderGetRequest,
  OrderOrderPaymentToggle,
  OrderPacketingByAdminMilestone,
  OrderPreparingMilestone,
  OrderShippingCompletedMilestone,
  OrderShippingFromOriginToDestinationMilestone,
  OrderSortingByAdminMilestone, OrderUpdateMilestoneByAdmin,
  OrderUpdateShippingCompletedMilestone,
  OrderUpdateShippingFromOrginToDestinationMilestone
} from "@/libs/Api/Order";
import Helper from "@/libs/Helper";
import {ProductUpdatePriceByMainId} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LiveStream from "@/views/components/LiveStream.vue";
export default {
  title:"Order info",
  name: "OrderInfo",
  data(){
    return{
      order:null,
      baseUrl:Helper.baseUrl,
      overlay:false,
      picture:null,
      orderId:this.$route.params.id,
      myTableColumnsSubOrders:[
        { key: 'name' ,label :'Product'},
        { key: 'quantity' ,label :'Quantity'},
        { key: 'unitName' ,label :'Unit'},
        { key: 'unitPrice' ,label :'Unit Price'},
        { key: 'finalPrice' ,label :'Final Price'},
        { key: 'sumPrice' ,label :'Sum Price'},
        { key: 'offPrice' ,label :'Off Price'},
        { key: 'currencyTitle' ,label :'Currency'},
      ],
      myTableColumnsSubOrders2:[
        { key: 'orderStatus' ,label :'orderStatus'},
        { key: 'files' ,label :'File'},
      ],
      selectedStep : 0,
      base64ImageSrc:null,
      file:{
        OrderId:this.$route.params.id,
        Description:'',
      }
    }
  },
  async created(){
    try{
      await this.getOrder();
    }catch (e){
      console.log(e)
    }finally {
      this.selectedStep = this.order.orderStatus;
    }
  },
  methods:{
    async getOrder(){
      let _this = this;
      _this.overlay = true;

      let orderGetRequest = new OrderGetRequest(_this);
      orderGetRequest.setParams({id:_this.orderId});
      await orderGetRequest.fetch(function (content){
        _this.overlay = false;
        _this.order = content.data;
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async togglePayment() {
      let _this = this;
      _this.overlay = true;

      let orderOrderPaymentToggle = new OrderOrderPaymentToggle(_this);
      orderOrderPaymentToggle.setParams({orderId:_this.$route.params.id});
      await orderOrderPaymentToggle.fetch((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async moveToSortingStep() {
      let _this = this;
      _this.overlay = true;

      let data = {
        OrderId:_this.orderId,
        Files:[],
        Description:''
      }

      let formData = Helper.makeFormData(data);

      let orderSortingByAdminMilestone = new OrderSortingByAdminMilestone(_this);
      orderSortingByAdminMilestone.setParams(formData);
      await orderSortingByAdminMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async moveToPacketingStep() {
      let _this = this;
      _this.overlay = true;

      let data = {
        OrderId:_this.orderId,
        Files:[],
        Description:''
      }

      let formData = Helper.makeFormData(data);

      let orderPacketingByAdminMilestone = new OrderPacketingByAdminMilestone(_this);
      orderPacketingByAdminMilestone.setParams(formData);
      await orderPacketingByAdminMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async moveToPreparingStep() {
      let _this = this;
      _this.overlay = true;

      let data = {
        OrderId:_this.orderId,
        Files:[],
        Description:''
      }

      let formData = Helper.makeFormData(data);

      let orderPreparingMilestone = new OrderPreparingMilestone(_this);
      orderPreparingMilestone.setParams(formData);
      await orderPreparingMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async moveToShippingStep() {
      let _this = this;
      _this.overlay = true;

      let data = {
        OrderId:_this.orderId,
        Files:[],
        Description:''
      }

      let formData = Helper.makeFormData(data);

      let orderShippingFromOriginToDestinationMilestone = new OrderShippingFromOriginToDestinationMilestone(_this);
      orderShippingFromOriginToDestinationMilestone.setParams(formData);
      await orderShippingFromOriginToDestinationMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async sendFile(){
      let _this = this;
      _this.overlay = true;

      let formData = Helper.makeFormData(_this.file);
      let upFile = _this.$refs.refInputEl.files[0];
      formData.append('Files', upFile);
      formData.append('Status', _this.selectedStep);
      let orderUpdateMilestoneByAdmin = new OrderUpdateMilestoneByAdmin(_this);
      orderUpdateMilestoneByAdmin.setParams(formData);
      await orderUpdateMilestoneByAdmin.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async moveToShippingCompletedStep() {
      let _this = this;
      _this.overlay = true;

      let data = {
        OrderId:_this.orderId,
        Files:[],
        Description:''
      }

      let formData = Helper.makeFormData(data);

      let orderShippingCompletedMilestone = new OrderShippingCompletedMilestone(_this);
      orderShippingCompletedMilestone.setParams(formData);
      await orderShippingCompletedMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async moveToOrderCompletedStep() {
      let _this = this;
      _this.overlay = true;

      let data = {
        OrderId:_this.orderId,
        Files:[],
        Description:''
      }

      let formData = Helper.makeFormData(data);

      let orderCloseOrderMilestone = new OrderCloseOrderMilestone(_this);
      orderCloseOrderMilestone.setParams(formData);
      await orderCloseOrderMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    changeStep(s){
      if (this.order.orderStatus >= s){
        this.selectedStep = s;
      }
    },
    setCover(e) {
      let file = e.target.files[0];
      this.base64ImageSrc = URL.createObjectURL(file);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("en-US",);
    },
    downloadPicture(){
      let link = document.createElement("a");
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'test');
      link.setAttribute('target', '_blank');
      link.href = this.baseUrl+this.picture;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },
  components: {
    LiveStream,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BAlert,
  },
}
</script>

<style>
.stepwizard-step p {
  margin-top: 10px;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 50%;
  position: relative;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  border: 1px solid #676d7d;
  background: #cccccc;
}
.bg-blue {
  border-color: #006699!important;
  background-color: #006699;
  color: white;
}
</style>
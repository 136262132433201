<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row class="w-100">
      <b-col cols="12" class="mb-2">
        <b-button
            v-if="!isRunning"
            variant="outline-primary"
            @click="getStreamId"
        >
          <feather-icon
              class="mr-50"
              icon="VideoIcon"
          />
          <span class="align-middle">Start live stream</span>
        </b-button>
        <b-button
            v-else
            variant="outline-danger"
            @click="stopRecording"
        >
          <span class="align-middle">Stop live stream</span>
        </b-button>
      </b-col>
      <b-col cols="12">
        <video v-show="isRunning" ref="videoElement" class="w-100 rounded-lg" autoplay style="height:max-content;max-height: 40rem;background: black"/>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {hubInstance} from '@/libs/vue-signalR';
import {LiveStreamCreateOrderLiveStream} from "@/libs/Api/Order";

export default {
  name: 'LiveStream',
  data() {
    return {
      overlay: false,
      videoStream: null,
      hub: null,
      mediaRecorder: null,
      stream: null,
      orderStreamId: 1,
      codec: 'video/webm;codecs=h264',
      recordingRunning: false,
      chunks: [],
      isRunning: false,
    }
  },
  props: ['order', 'selectedStep'],
  created() {
    this.hub = hubInstance.createNewHubInstance();
    hubInstance.connectToServer(() => {
      if (hubInstance.isConnected()) {

      }
    })
  },
  methods: {
    async startRecording() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({video: true});
        this.$refs.videoElement.srcObject = this.stream;
        this.recordingRunning = true;
        this.chunks = [];
        this.mediaRecorder = new MediaRecorder(this.stream, {
          mimeType: this.codec,
        });
        this.mediaRecorder.ondataavailable =  (e) => {
          this.chunks.push(e.data);
        };
        this.mediaRecorder.onstop = async ()=> {
          const actualChunks = this.chunks.splice(0, this.chunks.length);
          const blob = new Blob(actualChunks, {
            type: this.codec,
          });
          await this.uploadVideoPart(blob); // Upload to server
        };
        this.recordVideoChunk();
      }catch (error) {
        console.log(error,'tryCatchError')
        if (
            error.name === "NotFoundError" ||
            error.name === "DevicesNotFoundError"
        ) {
          alert("No camera found on your device.")
        } else if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
        ) {
          alert("please allow permissions for camera recording.")
        }
      }
    },
    // call this function to stop the process
    stopRecording() {
      this.recordingRunning = false;
      this.mediaRecorder.stop();
      this.$refs.videoElement.srcObject = null;
      this.isRunning = false;
    },
    recordVideoChunk() {
      this.isRunning = true;
      this.mediaRecorder.start();
      let _this = this;
      setTimeout(function () {
        if (_this.mediaRecorder.state === "recording") {
          _this.mediaRecorder.stop();
        }
        if (_this.recordingRunning) _this.recordVideoChunk();
      }, 10000); // 10 seconds videos
    },
    async uploadVideoPart(blob) {
      const arrayBuffer = new Uint8Array(await blob.arrayBuffer());
      const byteArray = Array.from(arrayBuffer); // Convert Uint8Array to standard array
      this.hub.invoke("UploadMedia", byteArray,this.orderStreamId);
    },
    async getStreamId() {
      let _this = this;
      _this.overlay = true;

      let data = {
        orderId: _this.order.id,
        liveStreamType: 1,
      }

      if (_this.selectedStep === 6) data.liveStreamType = 2;

      let liveStreamCreateOrderLiveStream = new LiveStreamCreateOrderLiveStream(_this);
      liveStreamCreateOrderLiveStream.setParams(data);
      await liveStreamCreateOrderLiveStream.fetch(async function (content) {
        _this.overlay = false;
        _this.orderStreamId = content.data.orderLiveStreamId;
        await _this.startRecording();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
  },
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BAlert,
  },
}
</script>

<style scoped>

</style>